import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import { getDefaultStreamingTileSliver } from 'lib/utils/streamingTiles'
import store from 'store'

export const useAddTile = () => {
  const currentGroup = useRecoilValue(store.streamingTiles.selectedGroupId)
  const setCurrentGroup = useSetRecoilState(
    store.streamingTiles.groupById(currentGroup),
  )

  const addTileToCurrentGroup = useCallback(() => {
    setCurrentGroup((prev) => {
      const next = Array.from(prev)
      next.push(getDefaultStreamingTileSliver())
      return next
    })
  }, [setCurrentGroup])

  return addTileToCurrentGroup
}
