import { useMemo, FC } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import store from 'store'

import { AddPairButton } from './AddPairButton'
import {
  ControlsContainer,
  GroupSelectorContainer,
} from './GroupsNavigator.styles'
import SetSelectedGroupIdButton from './SetSelectedGroupIdButton'

const Controls: FC = () => {
  const groupIds = useRecoilValue(store.streamingTiles.groupIds)
  const [selectedGroupId, setSelectedGroupId] = useRecoilState(
    store.streamingTiles.selectedGroupId,
  )

  const handlers = useMemo(
    () =>
      Object.fromEntries(
        groupIds.map((groupId) => [groupId, () => setSelectedGroupId(groupId)]),
      ),
    [setSelectedGroupId, groupIds],
  )

  return (
    <ControlsContainer>
      <GroupSelectorContainer>
        {groupIds.map((groupId) => (
          <SetSelectedGroupIdButton
            key={groupId}
            groupId={groupId}
            selected={selectedGroupId === groupId}
            onClick={handlers[groupId]}
          />
        ))}
      </GroupSelectorContainer>
      <AddPairButton />
    </ControlsContainer>
  )
}

export default Controls
