import 'twin.macro'
import tw from 'twin.macro'

const Container = tw.div`flex gap-2 items-center justify-center`
const Text = tw.div`font-bahnschrift text-light-400 font-semibold`

import PlusSquareIcon from 'assets/icons/plus-square.svg'
import { useAddTile } from 'hooks/streamingTiles'

export const AddPairButton = () => {
  const addTile = useAddTile()
  return (
    <button onClick={addTile}>
      <Container>
        <PlusSquareIcon tw="w-[20px] h-[20px] fill-light-400" />
        <Text>Add Pair</Text>
      </Container>
    </button>
  )
}
