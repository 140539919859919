import React from 'react'
import { useRecoilValue } from 'recoil'

import StreamingTileSlotConnector from 'components/StreamingTileSlotConnector'
import store from 'store'
import { GroupId } from 'types/group'

import { Container } from './StreamingTileGroup.styles'

export interface StreamingTileGroupProps {
  groupId: GroupId
}

const StreamingTileGroup: React.FC<StreamingTileGroupProps> = ({ groupId }) => {
  const group = useRecoilValue(store.streamingTiles.groupById(groupId))
  return (
    <Container>
      {group.map((_, index) => (
        <StreamingTileSlotConnector
          key={index}
          groupId={groupId}
          position={index}
        />
      ))}
    </Container>
  )
}

export default StreamingTileGroup
