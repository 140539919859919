import tw from 'twin.macro'

export const Container = tw.div`
  flex
  flex-col
  px-20
`

export const ControlsContainer = tw.div`
  flex
  gap-0.5
  py-0.5
  justify-between
`

export const GroupSelectorContainer = tw.div`
flex
gap-0.5
py-0.5
`
